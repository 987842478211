
import {
  defineComponent,
  provide,
  reactive,
  ref,
  InjectionKey,
  CSSProperties,
  PropType,
  readonly,
} from 'vue';
import { filterEmpty } from '@/utils/vnode-util';
import { SizeType } from '@/components/avatar-list/typing';
import AvatarListItem from './item.vue';

export interface AvatarItemInjectData {
  size: SizeType;
  excessItemsStyle?: CSSProperties;
}

export const avatarListKey: InjectionKey<AvatarItemInjectData> = Symbol();

export default defineComponent({
  name: 'AvatarList',
  props: {
    size: {
      type: String as PropType<SizeType>,
      default: 'default',
    },
    maxLength: {
      type: Number as PropType<number>,
      default: 5,
    },
    excessItemsStyle: {
      type: Object as PropType<CSSProperties>,
      default: () => undefined,
    },
  },
  setup(props, { slots }) {
    const children = filterEmpty(slots.default?.());
    const totalSize = ref(children.length);
    const injectProps = reactive({
      size: props.size,
      excessItemsStyle: props.excessItemsStyle,
    });
    provide(avatarListKey, readonly(injectProps));

    return {
      totalSize,
    };
  },
  components: {
    AvatarListItem,
  },
});
