
import { defineComponent } from 'vue';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { queryFakeList } from '@/api/list/basic-list';
import { dateFormatNow } from '@/utils/date-util';
import AvatarList from '@/components/avatar-list/list.vue';
import AvatarListItem from '@/components/avatar-list/item.vue';

export default defineComponent({
  name: 'Projects',
  setup() {
    const { reload, context } = useFetchData(() => {
      return new Promise<any>((resolve, reject) => {
        queryFakeList({ count: 16 })
          .then(list => {
            resolve({
              data: list,
              success: true,
              total: 16,
            });
          })
          .catch(reject);
      });
    });

    return {
      reload,
      context,

      dateFormatNow,
    };
  },
  components: {
    AvatarList,
    AvatarListItem,
  },
});
