
import { defineComponent, PropType, inject } from 'vue';
import { avatarListKey } from './list.vue';

export default defineComponent({
  name: 'AvatarListItem',
  props: {
    tips: {
      type: String as PropType<string>,
      default: () => undefined,
    },
    src: {
      type: String,
      default: '',
    },
  },
  setup() {
    const parentProps = inject(avatarListKey, { size: 'default', excessItemsStyle: {} });

    return {
      parentProps,
    };
  },
});
